import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t("seminars.videos.title")))]),_c('div',{staticClass:"my-4 d-flex"},[_c(VBtn,{attrs:{"small":"","elevation":"0"},on:{"click":function () { return _vm.$router.go(-1); }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v("Go back")])],1),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"other"}},on),[_c(VIcon,[_vm._v("mdi-sort")])],1)]}}])},[_c('div',{staticClass:"other"},[_c('h3',{staticClass:"mb-4 px-4 pt-4"},[_vm._v(" "+_vm._s(_vm.$t("seminars.videos.sortBy.title"))+" ")]),_c(VList,{staticClass:"pa-0 pb-4",attrs:{"dense":""}},[_c(VListItemGroup,{model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}},_vm._l((_vm.allSorts),function(s,i){return _c(VListItem,{key:i,attrs:{"value":s.value}},[_c(VListItemIcon,{staticStyle:{"margin-right":"16px"}},[(s.icon)?_c(VIcon,[_vm._v(_vm._s(s.icon))]):(s.emoji)?_c('span',{domProps:{"innerHTML":_vm._s(s.emoji)}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(s.text))])],1)],1)}),1)],1)],1)]),_c('div',{staticClass:"mx-1"}),_c(VMenu,{attrs:{"bottom":"","offset-y":"","max-width":"300px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"other"}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("seminars.videos.filter.users.title")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('div',{staticClass:"other"},[_c('div',{staticClass:"pa-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("seminars.videos.filter.users.detail"))+" ")]),_c(VTextField,{attrs:{"outlined":"","hide-details":"","background-color":"lightgray2","placeholder":_vm.$t('seminars.videos.filter.users.placeholder')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VList,{staticClass:"pa-0 pb-4 overflow-auto",attrs:{"max-height":"200px"}},[_c(VListItemGroup,{attrs:{"multiple":""},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},_vm._l((_vm.filteredUsers),function(u,i){return _c(VListItem,{key:i,attrs:{"value":u.id}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(u.username)+" ("+_vm._s(u.email)+") ")])],1)],1)}),1)],1)],1)]),_c('div',{staticClass:"mx-1"}),_c(VMenu,{attrs:{"bottom":"","offset-x":"","offset-y":"","max-width":"300px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"other"}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("seminars.videos.filter.elements.title")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('div',{staticClass:"other"},[_c('h3',{staticClass:"pa-4"},[_vm._v(" "+_vm._s(_vm.$t("seminars.videos.filter.elements.detail"))+" ")]),_c(VList,{staticClass:"pa-0 pb-4",attrs:{"max-height":"200px"}},[_c(VListItemGroup,{model:{value:(_vm.elId),callback:function ($$v) {_vm.elId=$$v},expression:"elId"}},_vm._l((_vm.elItems),function(t,i){return _c(VListItem,{key:i,attrs:{"value":t.value}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(t.text)+" ")])],1)],1)}),1)],1)],1)])],1),_c(VCard,{attrs:{"outlined":"","loading":_vm.getting}},[_c(VCardText,[(_vm.filteredSubmissions.length > 0)?_c(VRow,_vm._l((_vm.filteredSubmissions),function(s,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"3"}},[_c('VideoSubmission',{attrs:{"id":("video_" + i),"seminar":_vm.selected,"submission":s},on:{"rated":_vm.rated}})],1)}),1):_c('div',[_vm._v(_vm._s(_vm.$t("seminars.videos.noSubmissions")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }